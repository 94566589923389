export default () => {
  return [
    {
      id:'in-unsleepable-childrens-garden',
      title: '眠れない子供たちの庭',
      style: 'audio theater',
      materials: 'round speaker x6',
      year: '25 July, 2019',
      backgroundColor: '#A3B1B4CC',
      topImage: 'sleep_top.png',
      trimPosition: '0% 10%'
    },
    {
      id: 'my-own-private-sight',
      title: 'My Own Private Sight',
      style: 'installation',
      materials: 'round speaker x6, mirror display x3, light',
      year: '27 June, 2018',
      backgroundColor: '#E7B564CC',
      topImage: 'my-own-private-sight3.png',
      trimPosition: 'center center'
    },
    // {
    //   title: '夜の顔',
    //   style: 'audio theater',
    //   materials: 'round speaker x2, stand light x2, camera x2, projector x1, white screen x1, others...',
    //   year: '17 Feb, 2019',
    //   backgroundColor: '#E7B564CC',
    //   topImage: 'sleep_top.png',
    //   trimPosition: '0% 10%'
    // },
    {
      id: 'zero-identification',
      title: 'zero identification',
      style: 'installation',
      materials: 'round speaker x6, stand light x4, screen x1, mirrors x2, others...',
      year: '1 Feb, 2020',
      backgroundColor: '#F5B09BCC',
      topImage: 'no-identification_top.png',
      trimPosition: '0% 38%'
    },
    {
      id: 'zero-identification-window',
      title: 'zero identification: window',
      style: 'installation',
      materials: 'round speaker x2, display x1, steamer x1',
      year: '1 Feb, 2020',
      backgroundColor: '#5B8F8FCC',
      topImage: 'no-identification-window_top.png',
      trimPosition: '0% 23%'
    },
  ]
}