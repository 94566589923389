<template>
  <div v-loading="loading">
    <v-container>
      <v-row>
        <v-col cols="4">
          <div class="map-image" v-if="mapDetailComp">
            <MapDetail
              :src-path="cityMeta.image"
              :sound-list="mapPointList"
            />
          </div>
        </v-col>
        <v-col cols="7" v-if="mapDetailComp">
          <div class="archive-content">
            <div class="row-top-spacer" />
            <div class="left-spacer" />
            <div class="city-title">
              <p class="city-letter">{{ cityMeta.city }}</p>
            </div>
          </div>

          <div class="archive-content">
            <div class="prefecture-title">
              <p class="city-letter">{{ cityMeta.prefecture }}</p>
            </div>
            <div class="city-name">
              <p>{{ cityMeta.cityName }}</p>
            </div>
          </div>
          <div class="archive-content">
            <div class="left-spacer-detail" />
            <div class="city-detail">
              <p>railway<br>{{ cityMeta.railway }}<br>population:{{ cityMeta.population }}</p>
            </div>
            <div class="city-detail">
              <p>location<br>longitude:{{ cityMeta.longitude }} degrees<br>latitude:{{ cityMeta.latitude }} degrees</p>
            </div>
            <div class="city-detail">
              <p>area {{ cityMeta.area }}km^2<br>residential land: {{ cityMeta.residentialLand }}% field:{{ cityMeta.field }}% forest:{{ cityMeta.forest}}% wilderness:{{ cityMeta.wilderness }}%</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    

    <div class="icon-description">
      <div class="icon-content">
        <img src="@/icon/isNoiseReducted.png" width="35px" height="35px" />
        <span class="icon-note">  : ノイズ除去</span>
      </div>
      <div class="icon-content">
        <img src="@/icon/isRepaired.png" width="35px" height="35px" />
        <span class="icon-note">  : リペア</span>
      </div>
      <div class="icon-content">
        <img src="@/icon/isNormalized.png" width="35px" height="35px" />
        <span class="icon-note">  : ノーマライズ</span>
      </div>
      <div class="icon-spacer" />
    </div>

    <!-- table -->
    <el-table
      :data="soundList"
      style="width: 90%; padding-left: 8%;"
    >
      <el-table-column prop="title" label="title" />
      <el-table-column prop="placeName" label="place" />
      <el-table-column prop="recordingDate" label="date"  :width="120">
        <template slot-scope="scope">
          {{ String(scope.row.recordingDate).substring(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column prop="fileLength" label="length" :width="150"/>
      <el-table-column label="sample" :width="360">
        <template slot-scope="scope">
          <audio :src="require(`@/assets/sound/${scope.row.id.substring(0, 8)}.mp3`)" controls />
        </template>
      </el-table-column>
      <el-table-column label="condition">
        <template slot-scope="scope">
          <span>
            <img v-if="scope.row.isNoiseReducted" src="@/icon/isNoiseReducted.png" width="35px" height="35px" />
            <img v-else src="@/icon/isNoiseReducted_disabled.png" width="35px" height="35px" />
          </span>
          <span>
            <img v-if="scope.row.isRepaired" src="@/icon/isRepaired.png" width="35px" height="35px" />
            <img v-else src="@/icon/isRepaired_disabled.png" width="35px" height="35px" />
          </span>
          <span>
            <img v-if="scope.row.isNormalized" src="@/icon/isNormalized.png" width="35px" height="35px" />
            <img v-else src="@/icon/isNormalized_disabled.png" width="35px" height="35px" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="download">
        <template slot-scope="scope">
          <el-button 
            icon="el-icon-download"
            @click="onClickDownload(scope.row.downloadLink)"
            width="40px"
            height="40px"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import placeMstApi from '@/api/placeMst.api';
import cityMstApi from '@/api/cityMst.api';
import MapDetail from './mapDetail';
import hsvToHex from '@/util/hsvToHex';

export default {
  components:{
    MapDetail
  },
  data() {
    return {
      cityMeta: {
        prefecture: '',
        prefName: '',
        city: '',
        cityName: '',
        image: ''
      },
      placeList: [],
      soundList: [],  
      mapPointList: [],
      iconStyle: [],

      mapDetailComp: false,
      mapDetailProps: {},
      sounds: [],
      loading: true,
    }
  },

  async created() {
    // 情報取得
    this.$set(this.cityMeta, 'prefecture', this.$route.params.prefecture);
    this.$set(this.cityMeta, 'city', this.$route.query.city);
    // 都市情報
    const cityData = await cityMstApi.getCityMasterByCity(this.cityMeta.prefecture, this.cityMeta.city);
    this.$set(this.cityMeta, 'population', cityData.population);
    this.$set(this.cityMeta, 'railway', cityData.railway);
    this.$set(this.cityMeta, 'cityCode', cityData.cityCode);
    this.$set(this.cityMeta, 'longitude', cityData.longitude);
    this.$set(this.cityMeta, 'latitude', cityData.latitude);
    this.$set(this.cityMeta, 'area', cityData.area);
    this.$set(this.cityMeta, 'residentialLand', cityData.residentialLand);
    this.$set(this.cityMeta, 'field', cityData.field);
    this.$set(this.cityMeta, 'forest', cityData.forest);
    this.$set(this.cityMeta, 'wilderness', cityData.wilderness);
    this.$set(this.cityMeta, 'prefName', cityData.prefName);
    this.$set(this.cityMeta, 'cityName', cityData.cityName);
    this.$set(this.cityMeta, 'image', cityData.activeImage);

    const response = await placeMstApi.getPlaceByCity(this.cityMeta.prefecture, this.cityMeta.city);
    this.setMapPoint(response.results);
    this.initMap();

    this.setSoundList(response.results);
    this.$set(this, 'loading', false);
  },

  methods: {
    setMapPoint(placeData) {
      const placeList = [];
      const mapPointList = [];
      const styles = [];
      placeData.forEach((place, index) => {
        placeList.push({
          place: place.place,
          name: place.placeName
        });
        mapPointList.push({
          pointRatioX: place.pointRatioX,
          pointRatioY: place.pointRatioY
        });
        const style = {
          'background-color': `${hsvToHex([(index * 50) % 360, 1.0, 1.0])}77`,
          'border-radius': '50%',
          'width': '18px',
          'height': '18px',
          'display': 'inline-block',
          'margin': 'auto',
          'vertical-align': 'top',
          'margin-top': '4px'
        }
        styles.push(style);
      });

      this.$set(this, 'placeList', placeList);
      this.$set(this, 'mapPointList', mapPointList);
      this.$set(this, 'iconStyle', styles);
    },

    setSoundList(response) {
      const soundList = [];
      response.forEach(place => {
        place.soundArchives.forEach(sound => {
          sound.placeName = place.placeName;
          soundList.push(sound);
        })
      });
      this.$set(this, 'soundList', soundList);
    },

    initMap(){
      this.$set(this.mapDetailProps, 'srcPath', this.cityMeta.image);
      this.$set(this.mapDetailProps, 'soundList', this.mapPointList);
      
      this.$set(this, 'mapDetailComp', true);
    },

    async onClickDownload(link){
      if(link) {
        this.$confirm('ダウンロードしますか?', '', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'info'
        }).then(() => {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.download = link;
          a.href = link;
          a.click();
          a.remove();
        }).catch(() => {
          // 何もしない
        });
      } else {
        this.$message({
          type: 'error',
          message: 'ファイルが存在しません。'
        });
      }
    }
  }
}
</script>

<style>
.map-image {
  height: 400px;
  padding-top: 40px;
  text-align: right;
}

.archive-content {
  display: flex;
  flex-wrap: wrap;
}

.row-top-spacer {
  flex-basis: 99%;
  height: 120px;
  /* background-color:#7fffd455; */
}
.left-spacer {
  flex-basis: 44%;
  /* background-color:#0000ff55; */
}
.city-title {
  flex-basis: 55%;
  /* background-color: rgb(177, 159, 159); */
}
.city-letter{
  font-size: 44px;
  color: rgb(19, 19, 19);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: right;
  padding-right: 12px;
  height: 40px;
  line-height: 40px;
  letter-spacing: 4px;
}

.prefecture-title {
  flex-basis: 45%;
  margin-top: 24px;
  /* background-color:#dc143c55; */
}

.city-name{
  flex-basis: 55%;
  /* background-color: #00640055; */
}

.city-name p{
  font-size: 38px;
  color: rgb(70, 59, 59);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: right;
  padding-right: 20px;
  height: 40px;
  line-height: 0px;
  margin-top: -8px;
  letter-spacing: 4px;
  mix-blend-mode: difference; 
  white-space: nowrap;
}

.left-spacer-detail{
  flex-basis: 45%;
  /* background-color: #8b000055; */
}
.city-detail {
  flex-basis: 18%;
  /* background-color: #bdb76b55; */
}

.city-detail p{
  display: inline-block;
  text-align: justify;
  overflow-wrap: wrap;
  word-break: break-all;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -20px;
  padding-left: 12px;
}

#detail-map-area:before {
  content: '';
  position: absolute;
  top: 270px;
  left: 60%;/*線の上下位置*/
  display: inline-block;
  width: 0.25px;/*線の長さ*/
  height: 400px;/*線の太さ*/
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);/*位置調整*/
  background-color: #ddd;/*線の色*/
}

.icon-description {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 20px;
}
.icon-content {
  width: 170px;
}
.icon-content img {
  vertical-align: middle;
}
.icon-note {
  color: #666;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding-left: 12px;
}
.icon-spacer {
  flex-basis: 8.33%
}

.detail-place{
  font-size: 18px;
  padding-left: 20px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: #444;
}


#row-spacer {
  height: 150px;
}

</style>