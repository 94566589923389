<template>
  <div>
      <Detail />
  </div>
</template>

<script>
import Detail from '../components/Archives/Detail';

export default {
  name: 'ArchiveDetail',
  components: {
    Detail
  },
}
</script>
