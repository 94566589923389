<template>
  <div id="top-bar">
    <div style="height: 80px">
      <p id="name">Naoto Shibui</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#name {
  padding-left: 24px;
  padding-top: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: black;
  text-align: center;
  backdrop-filter: invert(10%) contrast(150%) blur(12px);
  -webkit-backdrop-filter: invert(10%) contrast(150%) blur(12px);
}
</style>