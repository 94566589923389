<template>
  <div>
    <v-container :fluid="true">
      <div id="archive-area">
        <v-row>
          <v-col cols="12">
            <div id="archive-title" v-on:mouseover="mouseOverAction" v-on:mouseleave="mouseLeaveAction" >
              <p>Field Rec. Sound Archive</p>
              <transition name="fade">
                <div id="archive-link-button" v-show="linkShow">
                  <p @click="onClickArchiveButton"> go to archive >>></p>
                </div>
              </transition>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      linkShow: false
    }
  },

  methods: {
    mouseOverAction() {
      this.$set(this, 'linkShow', true);
    },
    mouseLeaveAction() {
      this.$set(this, 'linkShow', false);
    },
    onClickArchiveButton(){
      this.$router.push('/archive')
    }
  }
}
</script>

<style scoped>
#archive-title {
  width: 650px;
  padding-top: 20px;
  margin-left: 20px;
  padding-left: 40px;
  font-size: 36px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: #333;
  transition: 0.8s;
  transition-timing-function: ease-out;
}

#archive-title:hover {
  font-size: 37px;
  background-color: rgba(211, 211, 211, 0.133);
  backdrop-filter: blur(8px) contrast(120%) sepia(5%);
}

#archive-area {
  height: 400px;
  margin-top: 60px;
  background-image: url('../../../assets/fieldrec.png');
	background-position: left 30% bottom 0%;
}

#archive-link-button {
  padding-right: 40px;
  padding-bottom: 4px;
  height: 40px;
  text-align: right;
  font-weight: thin;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color:cadetblue;
}

#archive-link-button:hover {
  cursor: pointer
}
#archive-link-button:active {
  font-size: 15.5px;

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  transition-timing-function: ease-in;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


</style>