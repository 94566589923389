<template>
  <div>
    <div class="top-bar">
      <TopBar />
    </div>
    <div>
      <WorkSumbnails />
    </div>
    <div>
      <ArchiveSumbnail v-if="isMobile"/>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import TopBar from '../components/TopBar';
import WorkSumbnails from '../components/Works/Sumbnail';
import ArchiveSumbnail from '../components/Archives/Sumbnail';
import AliveApi from '../api/alive.api';

export default {
  name: 'Home',
  components: {
    TopBar,
    WorkSumbnails,
    ArchiveSumbnail
  },

  created() {
    // device check
    const isMobile = navigator.userAgent.match(/iPhone|Android.+Mobile/);
    this.$set(this, 'isMobile', isMobile);
  },

  async mounted(){
    await AliveApi.alive();
  },

  data() {
    return{
      isMobile: true
    }
  }
}
</script>

<style scoped>
.top-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
}
</style>