<template>
  <div>
    <Works />
  </div>
</template>

<script>
import Works from '../components/Works'
export default {
  components: {
    Works
  }
}
</script>

<style>

</style>