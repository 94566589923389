import axios from 'axios'
import * as constants from '@/constants/baseUrl';

export default {
  async alive(){
    const requestURL = `${constants.API_BASE_URL}/monitoring`;
    const requestBody = new URLSearchParams();
    requestBody.append('header', this.getDefaultHeader());
    requestBody.append('data', {});

    try {
      const response = await axios.get(requestURL, requestBody);
      return response.data;
    } catch (error) {
      console.log('service error');
      console.error(error);
    }
  },

  getDefaultHeader(){
    return {
      'Content-Type': 'application/json',
    }
  }
}
