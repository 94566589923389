<template>
  <div>
    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no">
      <v-row class="content-row">
        <!-- Image -->
        <v-col xl="4" lg="4" md="4" sm="4" xs="12" id="image-area" v-if="isLeft">
          <v-img 
            :src="require(`../../../assets/works/${work.topImage}`)"
            :position="work.trimPosition"
            @click="onClickWork"
            class="work-sumbnail-image"
          />
        </v-col>

        <!-- Information -->
        <v-col xl="8" lg="8" md="8" sm="8" xs="12" :style="style" class="info-area">
          <v-container>
            <v-row class="info-spacer" no-gutters v-if="isSpacerOn">
              <!-- **spacer** -->
            </v-row>
            <v-row class="info-titile" no-gutters>
              <v-col cols="10" offset="1" :style="textPositionStyle">
                <p id="works-title" @click.stop="onClickWork"> {{ work.title }} </p>
              </v-col>
            </v-row>
            <v-row class="info-style" no-gutters>
              <v-col cols="10" offset="1" :style="textPositionStyle">
                <p class="works-description"> {{ work.style }} </p>
              </v-col>
            </v-row>
            <v-row class="info-description" no-gutters>
              <v-col cols="10" offset="1" :style="textPositionStyle">
                <p class="works-description"> {{ work.materials }} </p>
              </v-col>
            </v-row>
            <v-row class="info-description" no-gutters>
              <v-col cols="10" offset="1" :style="textPositionStyle">
                <p class="works-description"> {{ work.year }} </p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col xl="4" lg="4" md="4" sm="4" xs="12" id="image-area" v-if="!isLeft">
          <v-img 
            :src="require(`../../../assets/works/${work.topImage}`)"
            :position="work.trimPosition"
            @click="onClickWork"
            class="work-sumbnail-image"
          />
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  props: {
    work: {
      type: Object,
      default() {
        return {
          title: '',
          style: '',
          materials: '',
          year: '',
          image: '',
          mediaQuery: null,
          mediaQueryPhone: null
        }
      }
    },
    index: {
      type: Number,
      default(){
        return 0;
      }
    }
  },
  created() {
    this.$set(this, 'style', `background-color: ${this.work.backgroundColor};`);

    // image position
    this.$set(this, 'isLeft', this.index % 2 == 0);

    // information text position
    const side = this.index % 2 == 0 ? 'right' : 'left';
    this.$set(this, 'textPositionStyle', `text-align: ${side}`);

    // breakpoint
    this.mediaQuery = window.matchMedia('(max-width: 600px)');
    this.mediaQueryPhone = window.matchMedia('(max-width: 480px)');
    this.mediaQuery.addListener(this.handleBreakpoint);
    this.mediaQueryPhone.addListener(this.handleBreakpointPhone);
  },

  data() {
    return {
      textPositionStyle: '',
      isLeft: true,
      width: 600,
      height: 300,
      isSpacerOn: true,
      style: '',
      mediaQuery: null,
    }
  },

  methods: {
    onClickWork() {
      this.$router.push(`/works/${this.work.id}`)
    },
    handleBreakpoint(mediaQuery) {
      if(mediaQuery.matches) {
        this.$set(this, 'isLeft', true);
        this.$set(this, 'textPositionStyle', `text-align: left`);
        this.$set(this, 'isSpacerOn', false);
      } else {
        this.$set(this, 'isLeft', this.index % 2 == 0);
        const side = this.index % 2 == 0 ? 'right' : 'left';
        this.$set(this, 'textPositionStyle', `text-align: ${side}`);
        this.$set(this, 'isSpacerOn', true);
      }
    },
    handleBreakpointPhone(mediaQuery) {
      if(mediaQuery.matches) {
        this.$set(this, 'isSpacerOn', false);
      }else{
        this.$set(this, 'isSpacerOn', true);
      }
    }
  }

}
</script>

<style>
@media screen and (max-width: 1920px){
  .content-row{
    height: 312px;
  }
  #image-area {
    padding: 0px;
    display: inline;
  }
  .work-sumbnail-image {
    height: 300px;
    max-width: 650px;
  }
  .info-area {
    height: 300px;
  }
  .info-spacer {
    height: 60px;
  }
  .info-title {
    height: 80px;
  }
  .info-style {
    height: 32px;
  }
  .info-description {
    height: 32px;
  }

  #works-title {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
  }

  .works-description {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
}

@media screen and (max-width: 960px) {
	/* 960px以下に適用されるCSS（タブレット用） */
  .content-row{
    height: 192px;
  }
  #image-area {
    padding: 0px;
    display: inline;
  }
  .work-sumbnail-image {
    height: 180px;
    max-width: 650px;
  }
  .info-area {
    height: 180px;
  }
  .info-spacer {
    height: 20px;
  }
  .info-title {
    height: 32px;
  }
  .info-style {
    height: 20px;
  }
  .info-description {
    height: 20px;
  }

  #works-title {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
  }
  
  .works-description {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
}


@media screen and (max-width: 600px) {
	/* 600px以下に適用されるCSS（タブレット用） */
  .content-row{
    height: 276px;
  }

  #image-area {
    padding: 0px;
    display: inline;
  }

  .work-sumbnail-image {
    height: 120px;
    max-width: 650px;
  }
  .info-area {
    height: 156px;
  }
  .info-spacer {
    height: 36px;
  }
  .info-title {
    height: 48px;
  }
  .info-style {
    height: 24px;
  }
  .info-description {
    height: 24px;
  }

  #works-title {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
  }
  .works-description {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
	/* 480px以上に適用されるCSS（スマホ用） */
  .content-row{
    height: 276px;
  }
  #image-area {
    padding: 0px;
    display: inline;
  }
  .work-sumbnail-image {
    height: 120px;
    max-width: 650px;
  }
  .info-area {
    height: 156px;
  }
  .info-spacer {
    height: 36px;
  }
  .info-title {
    height: 48px;
  }
  .info-style {
    height: 24px;
  }
  .info-description {
    height: 24px;
  }

  #works-title {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
  }
  .works-description {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
  }
}

.work-sumbnail-image:hover {
  cursor: pointer;
}

#works-title:hover {
  cursor: pointer;
}
</style>