<template>
  <div>
    <!-- <canvas id="canvas"/> -->
    <v-img
      :src="require(`../../../assets/works/${workInfo.topImage}`)"
      :width="style.width"
      :max-width="maxWidth"
      :lazy-src="require(`../../../assets/works/${workInfo.topImageLazy}`)"
      :transition="'fade-transition'"
      id="work-main-icon"
    />

    <transition name="fade">
      <div :style="styleLeftTop" v-if="show">
        <v-container>
          <v-row>
            <div :style="spacerRow" />
          </v-row>
          <v-row no-gutters class="description-contents">
            <v-spacer />
            <v-col cols="7">
              system:
            </v-col>
          </v-row>
          <v-row no-gutters class="description-contents">
            <v-spacer />
            <v-col cols="7">
              <p>{{ workInfo.materials }}</p>
            </v-col>
          </v-row>

          <div v-for="(key, index) in Object.keys(workInfo.players)" :key="index">
            <v-row no-gutters class="description-contents">
            <v-spacer />
              <v-col cols="7">
                {{ key }}:
              </v-col>
            </v-row>
            <v-row no-gutters class="description-contents">
              <v-spacer />
              <v-col cols="7">
                <p>{{ workInfo.players[key] }}</p>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    workInfo: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    style() {
      return {
        width: this.width,
        height: this.height
      }
    },
    styleLeftTop(){
      return {
        position: 'absolute',
        width: `${this.width * 0.667}px`,
        height: `${this.width * 0.667}px`,
        top: `-${this. width * 0.667 * 0.2}px`,
        left: `-${this. width * 0.667 * 0.3}px`,
        'border-radius': '50%',
        'background-color': '#fef4ba22',
        'backdrop-filter': 'contrast(60%) brightness(150%) blur(10px)',
        'mix-blend-mode': 'multiply',
        'clip-path': `inset(20% 0px 0px 30%)`,
        'margin-top': `${this.imageTop}px`,
        'margin-left': `${this.imageLeft}px`
      }
    },
    spacerRow() {
      return {
        // 上40%のマージンをあけて、文字を配置
        height: `${this.width * 0.667 * 0.3}px`
      }
    }
  },

  data() {
    return {
      show: false,
      width: window.innerWidth * 0.7,
      height: window.innerWidth * 0.7 / 1920.0 * 1280,
      maxWidth: window.innerWidth * 0.7,
      
      targetElement: null,
      clientRect: null,
      imageTop: 140,
      imageLeft: 20,

      timeoutId: null
    }
  },

  created() {
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  mounted() {
    this.$set(this, 'targetElement', document.getElementById('work-main-icon'));
    this.$set(this, 'clientRect', this.targetElement.getBoundingClientRect());
    this.$set(this, 'imageTop', this.clientRect.top + window.pageYOffset);
    this.$set(this, 'imageLeft', this.clientRect.left + window.pageXOffset);
    setTimeout(() => {
      this.$set(this, 'show', true);
    }, 1500);
  },

  methods: {
    handleWindowResize() {
      if(this.timeoutId) return;

      this.timeoutId = setTimeout(() => {
        this.$set(this, 'width', window.innerWidth * 0.7);
        this.$set(this, 'height', this.width * (1280 / 1920));
        
        this.$set(this, 'clientRect', this.targetElement.getBoundingClientRect());
        this.$set(this, 'imageTop', this.clientRect.top + window.pageYOffset);
        this.$set(this, 'imageLeft', this.clientRect.left + window.pageXOffset);

        this.$set(this, 'timeoutId', null);
      }, 500);
    },

  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#overflow-text {
  position: absolute;
  top: 500px;
  /* left: 500px; */
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #fef4ba;
  /* background: radial-gradient(rgba(130, 130, 130, 0.863) 90%, rgb(252, 176, 176) 100%);  */
  backdrop-filter: blur(8px) contrast(120%) sepia(5%);

}

.description-contents p{
  padding-left: 20px;
}

#work-main-icon{
  clip-path: inset(0% 0 20% 0%);
}
</style>