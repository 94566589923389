export default class WorkDetail{
  data = {
    'in-unsleepable-childrens-garden': {
      title: '眠れない子供たちの庭',
      style: 'audio theater',
      materials: 'round speaker x6, Lavalier mic x1, laptop x1',
      year: '25 July, 2019', 
      players: {
        '脚本・出演': '満谷　樹',
        '演出': '満谷 樹, 澁井 直人',
        '音響': '澁井 直人'
      },
      topImage: 'sleep-icon.jpg',
      topImageLazy: 'sleep-icon-lazy.jpg',
      galleryImage: ['sleep00.png', 'sleep01.png', 'sleep02.png'],
      description:[
        '作家の満谷樹と共作した「聞こえない演劇」シリーズの第一弾。国立音楽大学図書館4Fの中庭で上演された。',
        '三面ガラス張りの9m×9mの中庭に役者が入り込み、ガラス越しに演技を行う。役者の声を直接聞く事はできず、役者に取り付けたマイクととスピーカーを通して、切れ切れの台詞がロビーに流される。',
        '',
      ],
      movie: null,
      movieSumbnail: null,
      recordingInfo: null,
      imageComponent: 'TopImageLeftCircle'
    },
    'my-own-private-sight': {
      title: 'My Own Private Sight',
      style: 'installation',
      materials: 'round speaker x6, mirror display, pc x1, light x6',
      year: '27 June, 2018', 
      players: {
        '音響・映像': '澁井 直人',
      },
      topImage: 'mops-icon.jpg',
      topImageLazy: 'mops-icon-lazy.jpg',
      description:[
        '6ch.サラウンド・映像・照明のためのインスタレーション。鑑賞者を取り囲むように配置した6つのスピーカーからは、環境音をベースに作成された音響が再生される。ディスプレイにはハーフミラーが取り付けられており、ディスプレイの明るさと部屋の照明を調節することで、鏡とディスプレイの2つの状態を自由に切り替えル事ができる。',
        '映像はサウンドとリンクし、あらかじめ用意された素材から音響とマッチするものが自動でピックアップされている。各シーンに合わせたエフェクトを、音から抽出したパラメータを元にリアルタイムで生成する。',
        '本作では「見る」ことの意識に注目している。鏡を見る時は、自身の特徴の中から、見たいものだけを抽出している。意識の中でデフォルメされた自身の姿を見続けるうちに、癖付いた認識の自分が出来上がっていることとだろう。',
        'ふとした時、鏡の反射によって突如現れる自分の姿と、デフォルメされた認識の中での自分の姿は大きく異なる様に見える。その不思議な違和感は、一瞬だけ鑑賞者に驚きと恐ろしさをもたらす。',
      ],
      movie: 'mops.mp4',
      movieSumbnail: 'mops-movie-sumbnail.jpg',
      recordingInfo: '2019年度卒業記念演奏会にて再上演<br/>国立音楽大学6-110',
      imageComponent: 'TopImageRightArk'
    },
    'zero-identification': {
      title: 'zero identification',
      style: 'installation',
      materials: 'round speaker x6, rear projection screen x1, mirror x10, pc x1, light x6',
      year: '1 Feb, 2020', 
      players: {
        '音響・映像': '澁井 直人',
      },
      topImage: 'zero-identification-icon.png',
      topImageLazy: 'zero-identification-icon-lazy.jpg',
      description:[
        '6ch.サラウンド・映像・鏡のインスタレーション作品',
        '異なる角度と高さで配置された複数の鏡が、鑑賞者を様々な角度から切り取る',
        '音響には各地で録音された環境音が利用されている。各音源はあらかじめ分析され、そのパラメータを用いてAIが自動的に音響をつなぎ合わせて再生する。'
      ],
      movie: 'zero-identification.mp4',
      movieSumbnail: 'zero-identification-sumbnail.png',
      recordingInfo: '2020年度修了作品審査会<br/>国立音楽大学N1-226',
      imageComponent: 'TopImageRightArk'
    },
    'zero-identification-window': {
      title: 'zero identification: window',
      style: 'installation',
      materials: 'round speaker x2,display x1, steamer x1',
      year: '1 Feb, 2020', 
      players: {
        '音響・映像': '澁井 直人',
      },
      topImage: 'zero-identification-window-icon.png',
      topImageLazy: 'zero-identification-window-icon-lazy.jpg',
      description:[
        'zero-identificationのもう一つの形態',
        '展示台の中にはディスプレイとスチーマ、2ch.のスピーカーが仕込まれている',
        '「窓ごしに景色を見る」というzero-identification作成時の発想を引き継ぎ、「窓」の要素に着目して再編成された作品。冬の結露した窓、吹きガラスから作られた昭和の歪んだ窓ガラス、サンドブラストされた曇りガラスなど、ぼやけて歪んで見える窓越しの景色を表現した' 
      ],
      movie: 'zero-identification-window.mp4',
      movieSumbnail: 'zero-identification-window-sumbnail.png',
      imageComponent: 'TopImageRightArk'
    },
  }

  getWorksData(title) {
    return this.data[title];
  }
}