<template>
  <div>
    {{ result }}
  </div>
</template>

<script>
import cityApi from '@/api/cityMst.api';

export default {
  data() {
    return {
      result: {}
    }
  },
  async created() {
    const response = await cityApi.getCityMasterByCity('Tokyo', 'musashimurayama');
    this.$set(this, 'result', response);
  }
}
</script>

<style>

</style>