<template>
  <div>
    <img :src="require(srcPath)" v-if="show" />
    <canvas id="canvas"/>
  </div>
</template>

<script>
import * as THREE from 'three';
import hsvToHex from '@/util/hsvToHex';

export default {
  props: {
    srcPath: {
      type: String,
      default: () => null
    },
    soundList: {
      type: Array,
      default: () => []
    }
  },
  computed:{
    canvasSize() {
      return {
        width: this.size,
        height: this.size
      }
    }
  },

  data() {
    return {
      size: 400,
      show: false,
      renderer: null
    }
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.init();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      this.$set(this, 'size', window.innerWidth / 1920 * 400);
      const canvas = document.getElementById('canvas');
      canvas.width = this.canvasSize.width;
      canvas.height = this.canvasSize.height;

      this.renderer.setSize(this.canvasSize.width, this.canvasSize.height);
    },

     async init(){
      /// レンダラーを作成
      this.renderer = new THREE.WebGLRenderer({
        canvas: document.querySelector('#canvas'),
        antialias: true,
        alpha: true,
      });
      this.renderer.setSize(this.canvasSize.width, this.canvasSize.height);
      
      // シーンを作成
      const scene = new THREE.Scene();
      scene.background = new THREE.Color('white');

      // カメラを作成
      const camera = new THREE.PerspectiveCamera(40, this.canvasSize.width / this.canvasSize.height, 1, 10000);
      camera.position.set(0, 0, 1000);

      // 球体を作成
      const geometry = new THREE.PlaneGeometry(512, 512, 512);
      // 画像を読み込む
      const loader = new THREE.TextureLoader().setCrossOrigin('localhost');
      const texture = loader.load(require(`@/assets/city/${this.srcPath}`), () => {console.log('map image loaded')})
      // マテリアルにテクスチャーを設定
      const material = new THREE.MeshStandardMaterial({
        map: texture,
      });
      // メッシュを作成
      const mesh = new THREE.Mesh(geometry, material);
      // 3D空間にメッシュを追加
      scene.add(mesh);

      let points= [];
      this.soundList.forEach((sound, index) => {
        const point = this.pointMesh(sound.pointRatioX, sound.pointRatioY, index);
        // const point = this.pointMesh(-0.417, -0.245, index);
        point.scale.x = 0.7;
        point.scale.y = 0.7;
        points.push(point);
        scene.add(point);
      })

      const light = new THREE.PointLight( 0xFFFFFF, 1.2, 800, -20);
      light.position.set( 100, 350, 1000 );
      scene.add( light );
      // FOG
      // scene.fog = new THREE.Fog(0x000000, 100, 7000);
      
      // 毎フレーム時に実行されるループイベントです
      const tick = () => {
        // メッシュを回転させる
        mesh.rotation.z = -0.3

        points.forEach(point => {
          point.scale.x *= 1.02;
          point.scale.y *= 1.02;
          if(point.scale.x > 20) {
            point.scale.x = 0.5;
            point.scale.y = 0.5;
          }
          point.material.opacity = Math.pow((20 - point.scale.x) / 20, 2)
        })
        
        // レンダリング
        this.renderer.render(scene, camera);
        // controls.update();

        requestAnimationFrame(tick);
      }

      tick();
    },

    pointMesh(ratioX, ratioY, index) {
      const geometry = new THREE.CircleGeometry(5, 32, 10);
      const color = hsvToHex([(index * 50) % 360, 1.0, 1.0]);
      const material = new THREE.MeshStandardMaterial({color, opacity: 0.3, transparent: true});
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(Math.floor(this.canvasSize.width * ratioX), Math.floor(this.canvasSize.height * ratioY), 100);
      mesh.rotation.z = -0.3
      return mesh
    }

  },

}
</script>

<style scoped>

</style>