<template>
  <div>
    <div id="works-title">
      <p>Works</p>
    </div>

    <SumbnailCard 
      v-for="(work, index) in works"
      :key="index"
      :work="work"
      :index="index"
    />
    <!-- <p id="more-works-text" @click="onClickAndMoreWorks">...and more >>></p> -->
  </div>
</template>

<script>
import SumbnailCard from './sumbnailCard';
import getWorkList from '../../../constants/workList'

export default {
  components: {
    SumbnailCard
  },
  created() {
    this.$set(this, 'works', getWorkList());
  },
  data() {
    return {
      works: []
    }
  },
  methods: {
    onClickAndMoreWorks(){
      this.$router.push('/works')
    }
  }
}
</script>

<style scoped>
#works-title {
  width: 400px;
  padding-top: 20px;
  margin: 20px;
  padding-left: 40px;
  font-size: 36px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: #333;
  transition: 0.65s;
  transition-timing-function: ease-out;
}

#more-works-text:hover {
  cursor: pointer;
}

#more-works-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: rgb(99, 184, 218);
  text-align: right;
  padding: 8px 16px;
}

</style>