<template>
  <div>
    <!-- <canvas id="canvas"/> -->
    <v-img
      :src="require(`../../../assets/works/${workInfo.topImage}`)"
      :width="style.width"
      :aspect-ratio="16/9"
      :lazy-src="require(`../../../assets/works/${workInfo.topImageLazy}`)"
      :transition="'fade-transition'"
      id="work-main-icon"
    />
    <transition name="fade">
      <div :style="styleLeftTop" v-if="show">
        <v-container>
          <v-row no-gutters class="description-contents">
            <v-spacer />
            <v-col cols="7">
              <p class="system-text">system:</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="description-contents">
            <v-spacer />
            <v-col cols="7">
              <p class="system-text">{{ workInfo.materials }}</p>
            </v-col>
          </v-row>

          <div v-for="(key, index) in Object.keys(workInfo.players)" :key="index">
            <v-row no-gutters class="description-contents">
            <v-spacer />
              <v-col cols="7">
                <p class="system-text">{{ key }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="description-contents">
              <v-spacer />
              <v-col cols="7">
                <p class="system-text">{{ workInfo.players[key] }}</p>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    workInfo: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    style() {
      return {
        width: this.width,
        height: this.height
      }
    },
    styleLeftTop(){
      return {
        position: 'absolute',
        width: `${this.imageSize.width * 0.7}px`,
        height: `${this.imageSize.height}px`,
        top: `0px`,
        left: `${this.imageSize.width * 0.3}px`,
        'background-color': '#fef4ba22',
        'backdrop-filter': 'contrast(60%) brightness(150%) blur(10px)',
        'mix-blend-mode': 'multiply',
        'clip-path': 'polygon(100% 0, 15% 0, 100% 70%)',
        'margin-top': `${this.imageTop}px`,
        'margin-left': `${this.imageLeft}px`
      }
    },
    spacerRow() {
      return {
        // 上40%のマージンをあけて、文字を配置
        height: `${this.width * 0.667 * 0.3}px`
      }
    }
  },

  data() {
    return {
      show: false,
      width: window.innerWidth * 0.7,
      height: window.innerWidth * 0.7 / 1920 * 1280,
      targetElement: null,
      clientRect: null,

      imageSize:{
        width: 0,
        height: 0
      },
      imageTop: 140,
      imageLeft: 20,

      timeoutId: null
    }
  },

  created() {
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  mounted() {
    this.$set(this, 'targetElement', document.getElementById('work-main-icon'));
    this.$set(this, 'clientRect', this.targetElement.getBoundingClientRect());
    this.$set(this, 'imageTop', this.clientRect.top + window.pageYOffset);
    this.$set(this, 'imageLeft', this.clientRect.left + window.pageXOffset);

    this.$set(this, 'width', window.innerWidth * 0.7);
    this.$set(this, 'height', this.width / 1920 * 1280 * 0.7);
    const size = {
      width: this.width,
      height: this.height
    };
    this.$set(this, 'imageSize', size);
    setTimeout(() => {
      this.$set(this, 'show', true);
    }, 1500);
  },

  methods: {
    handleWindowResize() {
      if(this.timeoutId) return;

      this.timeoutId = setTimeout(() => {
        this.$set(this, 'width', window.innerWidth * 0.7);
        this.$set(this, 'height', this.width / 1920 * 1280 * 0.7);
        
        const size = {
          width: this.width,
          height: this.height
        };
        this.$set(this, 'imageSize', size);

        this.$set(this, 'clientRect', this.targetElement.getBoundingClientRect());
        this.$set(this, 'imageTop', this.clientRect.top + window.pageYOffset);
        this.$set(this, 'imageLeft', this.clientRect.left + window.pageXOffset);

        this.$set(this, 'timeoutId', null);
      }, 500);
    },

  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.description-contents p{
  padding-left: 20px;
}

.system-text {
  text-align: right;
  padding-right: 20px;
  color: rgb(69, 65, 65);
}
</style>