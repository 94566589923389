<template>
  <div>

    <transition
      appear
      name="fade"
    >
      <div v-if="showPage">
        <v-container>
          <v-row>
            <v-col cols="11">
              <p class="index-text right-index-text" >{{ workInfo.title }}</p>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
        <div id="work-top-contents">
          <div id="top-item-image" :style="imageAreaHeight">
            <!-- <TopImageLeftTop :work-info="workInfo" v-if="false"/>
            <TopImageRightArk v-else :work-info="workInfo" /> -->
            <component :is="imageComponent" v-bind="imageComponentProps" />
          </div>

          <div id="top-item-description">
            <v-container>
              <v-row v-for="(description, index) in workInfo.description" :key="index">
                <!-- <v-spacer /> -->
                <v-col cols="8">
                  <p class="work-description">{{ description }}</p>
                </v-col>
                <v-spacer />
              </v-row>
              
            </v-container>
          </div>
        </div>

        <div v-if="workInfo.movie || workInfo.galleryImage">
          <!-- movie -->
          <v-container v-if="workInfo.movie" :fluid="true">
            <v-row>
              <v-spacer />
              <v-col cols="11">
                <p class="index-text left-index-text">Recorded</p>
                <p class="gallery-text" v-html="workInfo.recordingInfo" />
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="8">
                <video 
                  width="100%"
                  height="100%"
                  :src="require(`../../assets/works/movie/${workInfo.movie}`)"
                  :poster="require(`../../assets/works/${workInfo.movieSumbnail}`)"
                  preload="none" 
                  controls />
              </v-col>
            </v-row>
          </v-container>

          <!-- Galerry   -->
          <v-container v-if="workInfo.galleryImage" :fluid="true">
            <v-row>
              <v-spacer />
              <v-col cols="11">
                <p class="index-text left-index-text">Gallery</p>
                <p class="gallery-text">photo by shibui naoto</p>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="8">
                <v-carousel>
                  <v-carousel-item
                    v-for="(item,index) in workInfo.galleryImage"
                    :key="index"
                    :src="require(`../../assets/works/${item}`)"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  ></v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import WorkInfoList from '@/constants/workDetail';
import TopImageLeftTop from './ImageMask/TopImageLeftTop';
import TopImageRightArk from './ImageMask/TopImageRightArk';

export default {
  components: {
    TopImageLeftTop,
    TopImageRightArk
  },

  created() {
    this.$set(this, 'title', this.$route.params.title);
    const workList = new WorkInfoList();
    this.$set(this, 'workInfo', workList.getWorksData(this.title));

    this.$set(this, 'imageComponentProps', {workInfo: this.workInfo});
    switch(this.workInfo.imageComponent){
      case 'TopImageLeftCircle':
        this.$set(this, 'imageComponent', TopImageLeftTop);
        break;
      case 'TopImageRightArk':
        this.$set(this, 'imageComponent', TopImageRightArk);
        break;
    }
  },

  computed: {
    imageAreaHeight(){
      return {
        height: `${this.imageHeight}px`
      }
    }
  },

  data() {
    return {
      showPage: false,
      title: '',
      workInfo: {},
      imageHeight: window.innerWidth * 0.7 * (1080 / 1920),

      imageComponent: null,
      imageComponentProps: null,
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    // setTimeout(() => {
      this.$set(this, 'showPage', true);
    // }, 300)
    
  },

  methods: {
    handleResize() {
      this.$set(this, 'imageHeight', window.innerWidth * 0.7 * (1080 / 1920))
    }
  },

}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 3s cubic-bezier(1.0, 0.5, 0.8, 1.0);;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#work-top-contents {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#top-item-image {
  flex-basis: 1200px;
  margin-top: 60px;
  margin-left: 20px;
}

#top-item-description {
  flex-basis: 300px;
  margin-top: 60px;
  flex-grow: 3;
}

.index-text {
  height: 40px;
  padding-top: auto;
  line-height: 80px;
  font-size: 32px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: #4b4b4b;;
}

.right-index-text {
  text-align: right;
}

.left-index-text {
  text-align: left;
  padding-left: 20px;
}

.gallery-text {
  font-size: 12px;
  font-style: italic;
  margin-top: 40px;
  text-align: left;
  padding-left: 40px;
  color: #999;
}

.work-description {
  padding-left: 12px;
  color: #4b4b4b;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-align: justify;
  text-justify: inter-ideograph;
}

</style>